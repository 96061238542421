const routes = [
  {
    index: true,
    label: "Vlad Knyazev",
    path: '/',
  },
  {
    label: 'Resume',
    path: '/resume',
  },
  {
    label: 'Books',
    path: '/books',
  },
  {
    label: 'Travel map',
    path: '/travel-map',
  },
  {
    label: 'Photos',
    path: '/photos',
  },
  {
    label: 'Videos',
    path: '/videos',
  },
  {
    label: 'Contact',
    path: '/contact',
  },
];

export default routes;
